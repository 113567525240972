@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700&display=swap");

.button-holder {
  display: flex;
  align-items: center;
  width: 95%;
  max-width: 90vw;
  justify-content: center;
}
.button-column {
  display: contents;
  align-items: center;
  width: 100%;
  max-width: 90vw;
  justify-content: center;
  height: 100px;
}
.button-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 90vw;
  justify-content: center;
  height: 100px;
}
.apple-button {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  cursor: pointer;
}

.blue {
  background-color: #3378d0;
}
.red {
  background-color: #d03d33;
}
.green {
  background-color: #33d04d;
}
.yellow {
  background-color: #d0cd33;
}
.orange {
  background-color: #d07233;
}
.purple {
  background-color: #8933d0;
}
