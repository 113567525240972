/*
    ================================
        Best Viewed In Full Page
    ================================
*/

/* Because I'm using the fixed position for modals, if the screen height is small then you would not be able to see a full modal that's why it's recommended that you see this pen in full-page and not on editor view. */

/* defaults */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
}

a,
a:link {
  text-decoration: none;
}

a:focus {
  outline: none;
}

/* box */
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  padding: 0 4rem 2rem;
}

.box:not(:first-child) {
  height: 45rem;
}

.box__title {
  font-weight: normal;
  letter-spacing: 0.8rem;
  margin-bottom: 2.6rem;
}

.box__title::first-letter {
  color: var(--primary);
}

.box__info {
  margin-top: 6rem;
}

.box__note {
  line-height: 2;
}

/* modal */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  /* --m-background is set as inline style */
  background: var(--m-background);
  backdrop-filter: blur(5px);
}

/* using :target */
/*
when users will click/enter button(link) browser will add a #id in a url and when that happens we'll show our users the modal that contains that id.
*/
.modal-container:target {
  display: flex;
}

.modal {
  width: auto;
  height: auto;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 2rem 2rem;
  border-radius: 0.8rem;

  color: var(--light);
  background: var(--background);
  box-shadow: var(--m-shadow, 0.4rem 0.4rem 10.2rem 0.2rem) var(--shadow-1);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 95%;
  max-height: 95%;
}

.modal__btn {
  margin-top: 4rem;
  padding: 1rem 1.6rem;
  border: 1px solid var(--border-color);
  border-radius: 100rem;

  color: inherit;
  background: transparent;
  letter-spacing: 0.2rem;

  transition: 0.2s;
  cursor: pointer;
}

.modal__btn:nth-of-type(1) {
  margin-right: 1rem;
}

.modal__btn:hover,
.modal__btn:focus {
  background: var(--focus);
  border-color: var(--focus);
  transform: translateY(-0.2rem);
}

/* link-... */
.link-1 {
  color: var(--light);
  background: var(--background);
  box-shadow: 0.4rem 0.4rem 2.4rem 0.2rem var(--shadow-1);
  border-radius: 100rem;
  padding: 1.4rem 3.2rem;

  transition: 0.2s;
}

.link-1:hover,
.link-1:focus {
  transform: translateY(-0.2rem);
  box-shadow: 0 0 4.4rem 0.2rem var(--shadow-2);
}

.link-1:focus {
  box-shadow: 0 0 4.4rem 0.2rem var(--shadow-2),
    0 0 0 0.4rem var(--global-background), 0 0 0 0.5rem var(--focus);
}

.link-2 {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 100rem;

  color: inherit;

  position: absolute;
  top: 2rem;
  right: 2rem;
  margin: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
}

.link-2::before {
  content: "x";
  transform: translateY(-0.1rem);
}

.link-2:hover,
.link-2:focus {
  background: var(--focus);
  border-color: brown;
  background-color: brown;
  /*transform: translateY(-0.2rem);*/
}

.abs-site-link {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: hsla(0, 0%, 1000%, 0.6);
}
