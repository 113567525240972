@import url("adventureItem.css");
@import url("adventurePage.css");
@import url("backToTop.css");
@import url("buttons.css");
@import url("closeButton.css");
@import url("dropdownMenu.css");
@import url("flashyButton.css");
@import url("form.css");
@import url("glowyButton.css");
@import url("headerMainFooter.css");
@import url("inputSelect.css");
@import url("masterPage.css");
@import url("mobileAdjustments.css");
@import url("modal.css");
@import url("normalButton.css");
@import url("profilePage.css");
@import url("roundButton.css");
@import url("scrollBar.css");
@import url("sharpButton.css");
@import url("sliders.css");
@import url("slideup.css");
@import url("smallRoundButton.css");
@import url("table.css");
@import url("textStyles.css");
@import url("titles.css");
@import url("triangle.css");
@import url("wideButton.css");

#root {
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(41, 42, 58);
}

::-webkit-scrollbar {
  width: 8px;
  color: white;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: auto;
  font-family: "Nunito";
  color: white;
  background-position: center center;
  background: #8fd2dc;
}

body {
  --light: #fff;
  --primary: rgb(126, 76, 155);
  --focus: hsl(210, 90%, 50%);
  --border-color: hsl(0, 100%, 50%);
  --global-background: hsl(220, 25%, 10%);
  --background: linear-gradient(
    to right,
    hsl(210, 30%, 20%),
    hsl(255, 30%, 25%)
  );
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --shadow-2: hsla(236, 50%, 50%, 0.4);
}
