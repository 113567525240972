.glowy-button {
  --text-base: 277deg, 55%, 40%;
  --text-hover: 307deg, 72%, 86%;
  --shadow-start: 52deg, 100%, 72%;
  --shadow-end: 282deg, 90%, 50%;
  --shadow-inner: 277deg, 90%, 62%;
  all: unset;
  color: hsla(var(--text-base), 1);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-align: center;
  padding: 0.85em;
  max-width: 100%;
  width: 12em;
  background: inherit;
  background: hsla(277deg, 55%, 12%, 1);
  background-clip: padding-box;
  border: solid hsla(var(--text-base));
  border-radius: 2em;
  cursor: pointer;
  position: relative;
  transition: 0.25s ease-out;
}
.glowy-button::before,
.glowy-button::after {
  content: "";
  border-radius: inherit;
  transition: inherit;
  position: absolute;
  inset: 0;
  pointer-events: none;
}
.glowy-button::before {
  inset: -0.2em;
  z-index: -1;
  background: linear-gradient(
    hsla(var(--shadow-start), 1),
    hsla(var(--shadow-end), 1)
  );
  filter: blur(0.5em) saturate(1.2);
  transform-origin: bottom;
  transform: scaleY(0.5);
  opacity: 0;
}
.glowy-button::after {
  box-shadow: inset 0 0 0 0px #fff, 0 0 0 3px rgba(255, 255, 255, 0.7),
    0 0 0 3px #fff;
  mix-blend-mode: overlay;
  opacity: 0;
}
.glowy-button:hover,
.glowy-button:focus {
  color: hsla(var(--text-hover), 1);
  border-color: transparent;
  box-shadow: inset 0 1.4em 0 hsla(var(--shadow-inner), 0.1),
    inset 0 0 1.4em hsla(var(--shadow-inner), 0.32),
    0 1px 1px hsla(var(--shadow-inner), 0.32);
}
.glowy-button:hover::before,
.glowy-button:hover::after,
.glowy-button:focus::before,
.glowy-button:focus::after {
  transform: none;
  opacity: 1;
}

body,
.glowy-container {
  /*background: hsla(var(--page-background), 1);*/
}

.glowy-container {
  padding: 1em;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}
