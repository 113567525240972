.profile-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.user-description {
  width: 40%;
}

.description-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
}

.comment-text-area {
  word-wrap: break-word;
  height: auto;
  text-align: left;
  padding: 6px;
}
