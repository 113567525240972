:root {
  --back-to-top-offset: 200px;
  --back-to-top-button-size: 2.8em;
}
/* === relevant portion === */
#back-to-top {
  place-self: end;
  position: sticky;
  margin-top: calc(100vh + var(--back-to-top-offset));
  bottom: 2.7em;
  right: 2em;
}

#back-to-top {
  min-width: var(--back-to-top-button-size);
  height: var(--back-to-top-button-size);
  border-radius: 50%;

  display: grid;
  place-items: center;

  text-decoration: none;
  background-color: var(--color-accent);
  box-shadow: 0 0.6em 1em 0.2em var(--color-accent-alpha);
  transform: translateY(0%);
  transition: ease-out 200ms;
  transition-property: transform, background-color;
}

#back-to-top:hover {
  transform: translateY(-22%);
  background-color: var(--color-accent-bright);
}

#back-to-top::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-30%);
  background-color: rgba(0, 0, 0, 0);
  display: block;
}

#back-to-top {
  color: var(--color-accent);
  text-decoration-style: solid;
  text-decoration-thickness: 6px;
  text-decoration-color: var(--color-accent-alpha);
}
