@import url(https://unpkg.com/open-props/open-props.min.css);
@import url(https://unpkg.com/open-props/normalize.min.css);

.sideslide-body {
  display: grid;
  place-items: end;
  padding: 5px 30px;
}

.sideslide-ul {
  padding: 0;
  display: inline-grid;
  grid-auto-flow: column;
  list-style-type: none;
  transition: grid-template-columns 0.35s var(--ease-elastic-1);
}

.sideslide-li {
  display: grid;
  justify-content: end;
  z-index: var(--index);
}

.sideslide-nav {
  position: relative;
}

.sideslide-nav > button {
  position: absolute;
  left: 0;
  background: red;
  z-index: var(--count);
}

.sideslide-a {
  background: rgba(44, 21, 63, 0.48);
  display: inline-block;
  padding: var(--size-2) var(--size-4);
  border-radius: var(--radius-5);
  margin: 0;
  color: var(--gray-0);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-0);
  backdrop-filter: blur(40px);
  transition:
    color 0.2s,
    background 0.2s;
  height: 30px;
  cursor: pointer;
}

.sideslide-a:visited {
  color: var(--gray-0);
}

.sideslide-span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: 0.25rem;
  transition: opacity 0.35s; /* New - transition the opacity */
  cursor: pointer;
}

.sideslide-li:not(:last-child) .sideslide-span {
  opacity: var(--active, 0);
}

.sideslide-li:not(:last-child) .sideslide-a:hover,
.sideslide-li:not(:last-child) .sideslide-a:focus {
  background: var(--gray-0);
  color: var(--gray-12);
}

.sideslide-a:focus-visible {
  outline-color: transparent;
}

.sideslide-ul:focus-within,
.sideslide-ul:hover {
  --active: 1;
}
