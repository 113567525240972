#top-banner-element {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  font-weight: 700;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

#header-element {
  background: url("../images/banner01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
}

#main-element {
  background: linear-gradient(to bottom, #1daccc, #1eb2d3 20%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer-element {
  background: linear-gradient(to bottom, #1eb2d3, #90d2dc);
}

#main-element h2 {
  text-align: center;
}
