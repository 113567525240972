.select-filter-holder {
  display: flex;
  flex-direction: row;
}

.adventures-page {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adventure-table-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 680px;
  max-width: 90vw;
  background-color: linear-gradient(to bottom, #8fd2dc, #fff0, #8fd2dc);
}

.adventure-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 380px;
  max-width: 90vw;
  max-height: 400px;
  overflow-x: hidden;
}
