@property --r {
  syntax: "<length>";
  inherits: true;
  initial-value: 0;
}

.triangle {
  --r: 20px; /* the radius */
  width: 250px; /* the size */
  transform: rotate(90deg);
  aspect-ratio: 1.15;
  background: linear-gradient(45deg, #fdfdfd, #cacaca);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  --_g1: #0000 calc(3 * var(--r) / 2), #000 0;
  --_g2: calc(1.7 * var(--r)) bottom var(--r), #000 97%, #0000;
  --_m: radial-gradient(var(--r) at right var(--_g2)),
    radial-gradient(var(--r) at left var(--_g2)),
    radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 97%, #0000),
    linear-gradient(180deg, var(--_g1)) 50% 0 /100% 50%,
    linear-gradient(60deg, var(--_g1)) 0 100%/51% 51%,
    linear-gradient(-60deg, var(--_g1)) 100% 100%/51% 51%;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  cursor: pointer;
  transition: --r 0.5s;
  transform: scale(6%) rotate(90deg);
  position: relative;
  left: 0.75%;
  top: -0.2%;
}

.triangle:hover {
  --r: 8px !important;
}
