.close-container {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: space-around;
  position: absolute;
  left: 90%;
  top: 5%;
}

.close-container .close-button {
  position: relative;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-container .close-button:hover .close-inside {
}

.close-container .close-button .close-inside::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.15));
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.close-container .close-button:hover .close-inside::before {
  transform: skewX(45deg);
  left: 150%;
}

.close-container .close-button:hover::before /*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.close-container .close-button:hover::after /*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.close-inside {
  border: none;
}

.close-container .close-button .close-inside {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 0px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  font-weight: 700px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  background-color: #ff2b0f5e;
}

.close-container .close-button .close-inside:hover {
  background-color: #ff0f0f93;
  top: 1px;
  transition: 0.1s;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}

.close-container .close-skewed-container {
  transform: skewX(20deg);
  border-radius: 0px;
}

.close-button-text .close-skewed-container {
  transform: skewX(-20deg);
}
