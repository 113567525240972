/* titles.css */

h1 {
  min-width: 100%;
  text-align: center;
  padding: 0px 0px 0px;
}

h2 {
  min-width: 100%;
  text-align: center;
  padding: 0px 0px 20px;
}

h3 {
  min-width: 100%;
  text-align: center;
  padding: 0px 0px 20px;
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  h1 {
    font-size: 26px; /* Reduce font size for smaller screens */
  }
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  h2 {
    font-size: 22px; /* Reduce font size for smaller screens */
  }
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
  h3 {
    font-size: 18px; /* Reduce font size for smaller screens */
  }
}
