/**/
.wide-button-inside {
  border: none;
}

.wide-button-container .wide-button-inside {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  font-weight: 700px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

.wide-button-container:hover .wide-button-inside {
  letter-spacing: 3px;
}

.wide-button-container .wide-button-inside::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.15),
    transparent,
    rgba(255, 255, 255, 0.15)
  );
  transform: skewX(0deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.wide-button-container:hover .wide-button-inside::before {
  transform: scale(300%);
}
