.item-container {
  display: flex;
  flex-wrap: wrap;
  cursor: initial;
  justify-content: space-around;
  margin: 0px;
  max-width: 95%;
  min-width: 95%;
}

.modal .item-container {
  display: flex;
  flex-wrap: wrap;
  cursor: initial;
  justify-content: space-around;
  margin: 0px;
  max-width: 95%;
  min-width: 95%;
}

/*.item-container:hover {
  transition: 0.1s;
  transform: scaleX(100%);
}*/

.item-container .item-button {
  position: relative;
  top: 0;
  left: 0;
  height: 50px;
  margin: 0px 0px 10px 0px;
  min-width: 99%;
  display: grid;
  grid-template-columns: 80% 20%; /* two columns: first is 80%, second is 20% */
  grid-template-rows: 50% 50%; /* two rows each 50% */
  overflow: visible;
}

.item-container .item-button .item-inside::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.15));
  transform: skewX(45deg) translate(0);*/
  transition: 0.5s;
  filter: blur(0px);
}

/*.item-container .item-button:hover .item-inside::before {
  transform: skewX(45deg);
  left: 150%;
}

.item-container .item-button::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);

  width: 30px;
  height: 10px;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}
*/
/*.item-container .item-button:hover::before /*lightup button*/ /*{
  height: 50%;
  width: 80%;
  border-radius: 30px;
}*/

.item-container .item-button::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);

  width: 30px;
  height: 10px;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.item-container .item-button:hover::after /*lightup button*/ {
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.item-container .item-button::before,
.item-container .item-button::after {
  background: #2db2ff;
  box-shadow:
    0 0 5px #2db2ff,
    0 0 15px #2db2ff,
    0 0 30px #2db2ff,
    0 0 60px #2db2ff;
}

.item-container .item-button.red-glow::before,
.item-container .item-button.red-glow::after {
  background: #ff1f71;
  box-shadow:
    0 0 5px #ff1f71,
    0 0 15px #ff1f71,
    0 0 30px #ff1f71,
    0 0 60px #ff1f71;
}

.item-container .item-button.green-glow::before,
.item-container .item-button.green-glow::after {
  background: #1eff45;
  box-shadow:
    0 0 5px #1eff45,
    0 0 15px #1eff45,
    0 0 30px #1eff45,
    0 0 60px #1eff45;
}

.item-inside {
  border: none;
}

.item-container .item-button .item-inside {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  /*box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);*/
  padding: 0px;
  letter-spacing: 0px;
  text-decoration: none;
  overflow: visible;
  color: #fff;
  font-weight: 700px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

.item-text {
  padding: 20px;
  align-items: center;
}

.item-text.item-button:hover {
  color: blue;
}

.item-text {
  text-align: center;
  cursor: context-menu;
  border-style: none solid none none;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.1);
  overflow: visible;
  padding: 10px 3px 10px 3px;
}

.item-text.first {
  text-align: left;
}

.item-text.last {
  border-style: none;
  display: flex;
  width: 100%;
}

.item-container .item-button .item-inside.item-top {
  border-radius: 6px 0px 0px 0px;
  grid-column: 1;
  grid-row: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #37384e99;
}

.item-container .item-button .item-inside.item-bottom {
  border-radius: 0px 0px 0px 6px;
  grid-column: 1;
  grid-row: 2;
  background-color: #37384e99;
}

.item-container .item-button .item-inside.item-right {
  border-radius: 0px 6px 6px 0px;
  grid-column: 2;
  grid-row: 1 / span 2;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #37384e99;
}

.item-container .item-button .item-inside.item-header {
  background-color: #1921b899;
}

.item-container .item-button .item-inside.item-header .item-text:hover {
  background: #ffbc2d;
  box-shadow:
    0 0 5px #ffbc2d,
    0 0 15px #ffbc2d,
    0 0 30px #ffbc2d,
    0 0 60px #ffbc2d;
}
