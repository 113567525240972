.multi-choice-menu {
  position: relative;
}

.multi-choice-button-opener {
  /* Styles for your button opener */
}

.multi-choice-button-holder {
  z-index: 100;
  display: flex;
  position: absolute;
  bottom: 100%; /* Position the menu just above the opener button */
  left: 50%; /* Center the menu horizontally */
  transform: translateX(
    -50%
  ); /* Correct the position so that the center is exactly above the button */
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out; /* Adjust this value to your preference */
  color: var(--light);
  background: var(--background);
  box-shadow: var(--m-shadow, 0.4rem 0.4rem 10.2rem 0.2rem) var(--shadow-1);
  border-radius: 150px;
}

.multi-choice-button-holder.multi-choice-button-open {
  opacity: 1;
  visibility: visible;
}
