.general-table-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 95%;
}

.tr-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

table {
  border-radius: 20px;
  width: 95%;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
tr {
  max-width: 95%;
}
th,
td {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: auto;
  overflow-wrap: hide; /* Changed from overflow: wrap; */
  word-break: break-word; /* Ensure strings of text will also break */
}

th {
  text-align: left;
  white-space: nowrap;
}

thead th {
  background-color: #6000a2;
  position: sticky;
  z-index: 2;
  top: 0;
}

tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

tbody td {
  position: relative;
}

tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 600px) {
  th,
  td {
    font-size: 10px; /* Reduce font size for smaller screens */
  }
}
