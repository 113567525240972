.sharp-container .sharp-button .sharp-inside input {
  all: unset;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sharp-container {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: space-around;
  margin: 1px;
  /*transform: skewX(20deg);*/
}

.sharp-container .sharp-button {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sharp-container .sharp-button:hover .sharp-inside {
  letter-spacing: 3px;
}

.sharp-container .sharp-button .sharp-inside::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.15));
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.sharp-container .sharp-button:hover .sharp-inside::before {
  transform: skewX(45deg);
  left: 150%;
}

.sharp-container .sharp-button::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.sharp-container .sharp-button:hover::before /*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.sharp-container .sharp-button::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.sharp-container .sharp-button:hover::after /*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.sharp-container .sharp-button::before,
.sharp-container .sharp-button::after {
  background: #2db2ff;
  box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
    0 0 60px #2db2ff;
}

.sharp-container .sharp-button.red-glow::before,
.sharp-container .sharp-button.red-glow::after {
  background: #ff1f71;
  box-shadow: 0 0 5px #ff1f71, 0 0 15px #ff1f71, 0 0 30px #ff1f71,
    0 0 60px #ff1f71;
}

.sharp-container .sharp-button.green-glow::before,
.sharp-container .sharp-button.green-glow::after {
  background: #1eff45;
  box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
    0 0 60px #1eff45;
}

.sharp-inside {
  border: none;
}

.sharp-container .sharp-button .sharp-inside {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  padding: 0px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  font-weight: 700px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

.sharp-button-text {
  /*transform: skewX(-20deg);*/
}
