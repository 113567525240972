/* Responsive design for smaller screens */

/* iPhone XR -LS*/

@media only screen and (max-width: 600px) {
  .modal {
    font-size: 10px; /* Reduce font size for smaller screens */
  }

  .item-container .item-button {
    grid-template-columns: 70% 30%; /* two columns: first is 80%, second is 20% */
    grid-template-rows: 50% 50%; /* two rows each 50% */
  }

  .item-container {
    min-width: 500px; /* Reduce font size for smaller screens */
    font-size: 12px; /* Reduce font size for smaller screens */
  }

  .close-container {
    transform: scale(85%);
    left: 85%;
    top: 2%;
  }

  .description-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .user-description {
    width: 80%;
  }

  .round-container {
    transform: scale(85%);
    left: 85%;
    top: 2%;
  }
}

/* iPhone XR -P */
@media only screen and (max-width: 500px) {
  .item-container {
    font-size: 10px; /* Reduce font size for smaller screens */
  }

  .item-container {
    min-width: 380px; /* Reduce font size for smaller screens */
    font-size: 12px; /* Reduce font size for smaller screens */
  }
}

/* iPhone XR -P */
@media only screen and (max-width: 414px) {
  .item-container {
    font-size: 10px; /* Reduce font size for smaller screens */
  }

  .item-container {
    min-width: 95%; /* Reduce font size for smaller screens */
    font-size: 12px; /* Reduce font size for smaller screens */
  }

  .adventure-table-window {
    min-width: 400px;
  }
}
