textarea {
  border-radius: 6px;
  text-align: center;
  height: 24px;
}

.form-row {
  display: flex;
}

.form-col {
  margin-bottom: 0.7rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-col label {
  margin-bottom: 0.3rem;
  height: 24px;
}

.form-col input {
  margin-bottom: 0.3rem;
}

.form-col-single {
  flex-direction: column;
}

.form-col-double {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 10px;
}

.form-field {
  display: contents; /* makes the .form-field div act like it's not there, so its children align directly with the grid */
}

.submission-inputs {
  border-radius: 6px;
  text-align: center;
  height: 24px;
}

.submission-texts {
  height: 24px;
}

.submission-textarea {
  border-radius: 6px;
  text-align: left;
  height: 122px;
}

.submission-selects {
  width: 33.333%;
  display: flex;
}

.optional-fields {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.optional-fields.show {
  max-height: 500px; /* Adjust this value based on your needs */
}
